<template>
    <div class="px-6">
        <div class="text-center" width="100%">
            <v-icon x-large>{{ icon }}</v-icon>
            <div class="my-2">
                {{ subTitle }}
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: ['icon', 'subTitle']
}
</script>
